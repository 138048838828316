@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Manrope:wght@200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Space+Grotesk:wght@300..700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Inter:wght@100&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Manrope:wght@200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;400&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  font-size: 10px;
  font-family: "Figtree", sans-serif;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
  cursor: pointer;
}
ul {
  list-style: none;
}

.mainContainer {
  max-width: 1200px;
  margin: 0 auto;
}

.container {
  width: 100%;
  height: 100vh;
  padding: 0px 120px;
  max-width: 1440px;
  margin: auto;
  @media (max-width: 1280px) {
    padding: 0px 100px;
  }
  @media (max-width: 1150px) {
    padding: 0px 70px;
  }
  @media (max-width: 750px) {
    padding: 0px 16px;
  }
}

// Inital Loader

.loader-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 99999;
  transition: all 1s ease;

  video {
    width: 630px;
    height: 630px;
  }
  @media (max-width: 768px) {
    video {
      width: 300px;
      height: 300px;
    }
  }
}
.loader-container-active {
  transform: translateY(-150%);
  transition: all 1s ease;
}
.website-container {
  animation-name: backInUp;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  transition: all 5s ease-in-out;
}
// @keyframes backInUp {
//   0% {
//     transform: translateY(1200px);
//     opacity: 0.7;
//   }

//   80% {
//     transform: translateY(0px);
//     opacity: 0.7;
//   }

//   100% {
//     transform: translateY(0px);
//     opacity: 1;
//   }
// }
