@import "../../styles/variables.scss";

.slick-slider .slick-track,
.slick-slider .slick-list {
  border-radius: 12px 0 0 12px;
}

.data-counters-container {
  height: 30vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  overflow: hidden;
  padding: 0px 100px 0 145px;
  .data-container {
    width: 100%;
    max-width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .plusIcon {
      position: absolute;
      right: 14%;
      top: -25%;
      position: absolute;
      width: fit-content;
      height: min-content;
      display: flex;
      align-self: flex-start;
      color: var(--Gray-300, #d6d6d6) !important;
      font-size: 52px;
      font-style: normal;
      font-weight: 100;
      font-family: "Sora", sans-serif;
      -webkit-text-fill-color: #d6d6d6;
      line-height: 90px;
    }
    &:first-child {
      .plusIcon {
        right: 30%;
      }
    }
  }
  .scrolling-text-container {
    width: 100%;
    height: 100%;
    height: 90px;
    overflow: hidden;
  }
  .content-list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    top: 0;
    left: 0%;
    transform: translate3d(0, -4%, 0);
    transition: all 2s ease-in-out;
    &Plus {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .content-list-clients {
    transform: translate3d(0, -2%, 0) !important;
  }
  .scroll-effect-1 {
    transform: translate3d(0, -60%, 0);
    transition: all 2s ease-in-out;
  }
  .scroll-effect-2 {
    transform: translate3d(0, -72%, 0);
    transition: all 3s ease-in-out;
  }
  .scroll-effect-3 {
    transform: translate3d(0, -89.5%, 0);
    transition: all 5s ease-in-out;
  }
  .content-item {
    margin-bottom: 1rem;
  }

  h3 {
    width: 100%;
    @include gradiantHeading(96px, 130%, 300);
    background: linear-gradient(
      130deg,
      #7577f7 20.28%,
      #418ec8 32.1%,
      #595998 44.36%,
      #c35289 53.64%,
      #d9586a 11.95%,
      #f1a060 90.65%
    ) !important;
    -webkit-background-clip: text !important;
    background-clip: border-box;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: flex-start;
    font-family: "Space Grotesk";
    color: linear-gradient(
      118deg,
      #7577f7 13.08%,
      #418ec8 28.59%,
      #595998 41%,
      #c35289 54.8%,
      #d9586a 69.28%,
      #f1a060 79.27%
    );

    span {
      width: 30px;
      height: min-content;
      display: flex;
      align-self: flex-start;
      color: var(--Gray-300, #d6d6d6) !important;
      font-size: 52px;
      font-style: normal;
      font-weight: 100;
      font-family: "Sora", sans-serif;
      -webkit-text-fill-color: #d6d6d6;
      line-height: 90px;
    }
  }
  p {
    width: 90%;
    @include regularParaghaph(18px, 140%, 400);
    position: relative;
    align-self: flex-start;
    font-family: "Figtree", sans-serif !important;
    line-height: 140% !important;
    color: #424242;
    // span {
    //   position: absolute;
    //   top: -117px;
    //   width: fit-content;
    //   height: min-content;
    //   display: flex;
    //   align-self: flex-start;
    //   color: var(--Gray-300, #d6d6d6) !important;
    //   font-size: 52px;
    //   font-style: normal;
    //   font-weight: 100;
    //   font-family: "Sora", sans-serif;
    //   -webkit-text-fill-color: #d6d6d6;
    //   line-height: 90px;
    //   right: 6px;
    // }
  }
  .left-arc {
    display: flex;
    position: absolute;
    width: 179px;
    height: 279px;
    flex-shrink: 0;
    left: -50px;
    top: 0;
  }
  .right-arc {
    display: flex;
    position: absolute;
    width: 160px;
    height: 270px;
    flex-shrink: 0;
    right: -50px;
    top: 0;
  }
  .divider-icon {
    display: block;
    height: 73px;
    margin: 0 10px 43px 0;
  }
  .right-star {
    display: flex;
    position: absolute;
    flex-shrink: 0;
    right: 32px;
    top: 79px;
    width: 65px;
    height: 65px;
  }
  .left-star {
    display: flex;
    position: absolute;
    flex-shrink: 0;
    left: 40px;
    top: 90px;
    width: 47px;
    height: 47px;
  }
}
@media (max-width: 750px) {
  .data-counters-container {
    flex-wrap: wrap;
    height: fit-content;
    gap: 10px;
    justify-content: space-between;
    .data-container {
      width: 45%;
      min-height: 180px;
      p {
        width: 99%;
        font-size: 16.5px;
        color: #424242;
      }
      h2 {
        font-size: 80px;
      }
    }
    .divider-icon {
      display: none;
    }
    .left-star,
    .right-star,
    .right-arc,
    .left-arc {
      display: none;
    }
  }
}

@media (max-width: 1039px) {
  .data-counters-container {
    padding: 0px 60px 0 90px;

    .data-container {
      &:first-child {
        p {
          span {
            right: 30px;
          }
        }
      }
    }
    .divider-icon {
      display: none;
    }
    h3 {
      font-size: 80px;
    }
    p {
      font-size: 15px;
      color: #424242;
      span {
        right: 12px;
        top: -120px;
        font-size: 44px;
      }
    }
    .right-arc {
      display: none;
    }
    .left-arc {
      display: none;
    }
    .right-star {
      display: none;
    }
    .left-star {
      display: none;
    }
  }

  .people-says-container {
    padding: 60px 0 0 20px !important;
    margin-top: 0 !important;
    .slider-container {
      margin-top: 60px !important;
    }
    .slick-arrow.slick-next {
      right: 5px !important;
      bottom: -90px;
      top: inherit !important;
    }
    .slick-arrow.slick-prev {
      right: 55px !important;
      bottom: -90px;
      top: inherit !important;
      left: inherit !important;
      z-index: 2;
    }

    .slick-slide {
      margin: 0 -30px;
      height: inherit !important;
    }
    .slick-track {
      display: flex !important;
    }
    .slick-list {
      margin: 0 -5px;
    }
    .slick-slider {
      // overflow: hidden;
    }
  }
}

@media (max-width: 768px) {
  .data-counters-container {
    padding: 0px 20px;

    .data-container {
      .plusIcon {
        top: -2%;
      }

      p {
        font-size: 16.5px;
        color: #424242;
      }
    }
  }
  .people-says-container {
    background-size: 122% 125% !important;

    .title-container {
      h2 {
        font-size: 32px !important;
      }
      h3 {
        font-size: 32px !important;
      }
    }

    .slick-list {
      margin: 0 14px;
      // width: 100%;
    }

    .slick-slide {
      margin: 0 8px;
      // width: 312px !important;
    }

    .slider-container {
      margin-top: 64px !important;
    }
  }
}

.people-says-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  margin-top: 72px;
  padding-top: 0;
  background-image: url("../../assets//images//dataCounter/Flare-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .title-container {
    width: 100%;
    h2 {
      width: 100%;
      text-align: left;
      @include regularheading(70px, 120%, 500);
    }
    h3 {
      @include gradiantHeading(70px, 120%, 500);
      width: fit-content;
    }
  }
  .slider {
    width: 99%;
  }
  .people-opinion {
    max-width: 350px;
    width: 22%;
    height: 200px;
    padding: 24px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 12px;
    border: 1px solid rgba(246, 246, 246, 0);
    background: linear-gradient(
      180deg,
      #fff 0.37%,
      #fff 102.78%,
      #bebeef 132.71%
    );
    box-shadow: 0px 8px 30px 0px rgba(89, 89, 152, 0.06);
    @media (max-width: 1340px) {
      max-width: 450px;
      width: 20%;
    }
    @media (max-width: 1140px) {
      max-width: 310px !important;
      width: 20%;
    }
    @media (max-width: 992px) {
      max-width: 300px;
      width: 50%;
    }
    @media (max-width: 768px) {
      max-width: none;
      width: 100%;
      padding: 28px 20px;
      // height: 235px;
    }

    .author-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      img {
        max-width: 41px;
      }
    }
    .opinion {
      @include regularParaghaph(18px, 140%, 400);
      font-family: "Figtree", sans-serif !important;
      line-height: 140% !important;
    }
    .author-name {
      @include regularheading(16px, 130%, 600);
      font-family: "Figtree", sans-serif !important;
      line-height: 140% !important;
    }
    .author-role {
      @include regularParaghaph(16px, 130%, 400);
      font-family: "Figtree", sans-serif !important;
      line-height: 140% !important;
    }
  }
  .slider-container {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 128px;
  }
  .slick-arrow.slick-next {
    width: 40px;
    height: 40px;
    background-image: url("../../assets/images/dataCounter/nextArrow.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: -50px;
    top: 50%;
  }
  .slick-prev:before,
  .slick-next:before {
    content: "" !important;
  }
  .slick-arrow.slick-prev {
    width: 40px;
    height: 40px;
    background-image: url("../../assets/images/dataCounter/prevArrow.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: -40px;
    top: 50%;
  }
  @media (max-width: 750px) {
    .slick-initialized .slick-slide {
      display: block;
      // width: 312px !important;
    }

    .slick-arrow.slick-prev {
      width: 40px;
      height: 40px;
      background-image: url("../../assets/images/dataCounter/prevArrow.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 250px;
      top: 125%;
    }
    .slick-arrow.slick-next {
      width: 40px;
      height: 40px;
      background-image: url("../../assets/images/dataCounter/nextArrow.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -10px;
      top: 125%;
    }
  }
  @media (max-width: 1300px) {
    .slick-slide {
      // margin: 0 10px;
    }
    .people-opinion {
      max-width: 330px;
    }
  }
  @media (max-width: 1039px) {
    .people-opinion {
      max-width: 305px;
    }
  }
}
