@import "../../styles/variables.scss";

.hello-from-us-main-container {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 72px;
  position: relative;
  overflow: hidden;

  .next-page-indicator {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/images/HomeBanner/lineFrame.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    img {
      width: 17%;
      height: 34%;
      object-fit: contain;
    }
  }
  .main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .title-container {
      h2 {
        @include regularheading(70px, 140%, 500);
        line-height: 140% !important;
      }
      h3 {
        @include gradiantHeading(70px, 120%, 500);
        line-height: 140% !important;
      }
    }
  }
  .welcome-text-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 12px;
  }
  .empty-towers {
    width: 100%;
    height: 561px;
    background: #fff;
    position: relative;
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
    // aspect-ratio: 1/1;
  }

  .full-tower {
    height: 100%;
    display: block;
    position: absolute;
    // top: 0;
    // left: 25%;
    z-index: 0;
    // transform: scale(1);
    // animation-duration: 1s;
    // animation-name: loadImage;
    // animation-delay: 0s;
    // animation-iteration-count: 1;
    transition: all 0.5s ease;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
  .zoomed-tower {
    height: 100%;
    position: absolute;
    // top: 40%;
    // left: 25%;
    z-index: 0;
    transition: all 0.5s ease;
    transform: scale(1.7);

    top: 50%;
    left: 50%;
    transform: translate(-50%, -5%) scale(1.7);
  }

  .about-us {
    width: 35%;
    position: absolute;
    left: 7%;
    top: 55px;
    @include regularParaghaph(24px, 140%);
    font-family: "Figtree", sans-serif !important;
    line-height: 140% !important;
    text-align: right;
    z-index: 10;
    color: #292929;
    transition: all 1s ease-in-out;
  }
  .our-passion {
    width: 35%;
    position: absolute;
    right: 5%;
    top: 55px;
    line-height: 140% !important;
    @include regularParaghaph(24px, 140%);
    font-family: "Figtree", sans-serif !important;
    z-index: 10;
    transition: all 1s ease-in-out;
    color: #424242;
  }
}
@keyframes loadImage {
  from {
    transform: scale(0.6);
  }

  to {
    transform: scale(1);
  }
}

@media (max-height: 776px) {
  .hello-from-us-main-container {
    .zoomed-tower {
      // left: 28%;
    }
  }
}

@media (max-height: 705px) {
  .hello-from-us-main-container {
    .zoomed-tower {
      // left: 30%;
    }
  }
}

@media (max-width: 1280px) {
  .hello-from-us-main-container {
    .our-passion {
      right: 4%;
    }
    .about-us {
      left: 7%;
    }
  }
}

@media (max-width: 1366px) {
  .hello-from-us-main-container {
    .about-us {
      left: 6%;
    }
  }
}

@media (max-width: 1039px) {
  .hello-from-us-main-container {
    height: fit-content;
    padding: 0 20px;

    .empty-towers,
    .full-tower,
    .zoomed-tower {
      position: static;
      animation: none;
      transition: none;
      transform: scale(1);
      height: 100%;
      opacity: 1 !important;
      display: flex;
      flex-direction: column;
      gap: 37px;
      max-width: 100%;
      width: 100%;
    }
    .full-tower,
    .zoomed-tower {
      max-width: 619px;
      margin: 0 auto;
    }
    .welcome-text-container {
      flex-direction: column;
      gap: 24px;
      p {
        position: static;
        width: 100%;
        text-align: left;
        line-height: 140%;
      }
    }
    .about-us {
       font-size: 15px;
       position: static;
       width: 100%;
       text-align: left;
     }
    .our-passion {
       font-size: 15px;
       position: static;
       width: 100%;
       text-align: left;
     }
  }
}

@media (max-width: 768px) {
  .hello-from-us-main-container {
    .full-tower,
    .zoomed-tower {
      max-width: inherit;
      margin: inherit !important;
      opacity: 1 !important;
    }
    .title-container {
      h2 {
        font-size: 32px !important;
      }
      h3 {
        font-size: 32px !important;
      }
    }
  }
}
