@import "../../styles//variables.scss";
.header-main-container {
  width: 100% !important;
  height: 46px !important;
  position: fixed;
  top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 999;
  margin: auto;
  padding: 0px 110px;
  @media (max-width: 1280px) {
    padding: 0px 100px;
  }
  @media (max-width: 1150px) {
    padding: 0px 20px;
  }
  @media (max-width: 750px) {
    padding: 0px 16px;
  }

  a {
    text-align: left;
    font-family: "Space Grotesk";
    color: #525252;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-family: "Figtree";
    cursor: pointer;
  }
  .header-sub-container {
    width: 100%;
    height: 100%;
    border-radius: 23px;
    border: 0.5px solid #fff;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 50px 0px rgba(65, 0, 96, 0.06);
    backdrop-filter: blur(12.5px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 13px 8px 13px 24px;
    position: relative;
    .logo-container {
      width: 147px;
      height: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .link-container {
      flex-basis: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 40px;
      .header-links {
        @include regularParaghaph(14px, 140%, 400);
        font-family: "Figtree";
        cursor: pointer;
        &:hover {
          @include gradiantHeading(14px, 140%, 400);
          font-family: "Figtree";
          color: linear-gradient(
            106deg,
            #7577f7 -5.25%,
            #418ec8 18.03%,
            #595998 43.26%,
            #c35289 74.32%,
            #d9586a 101.94%,
            #f1a060 121.01%
          );
        }
      }
    }
    .mobile-menu {
      display: none;
    }
    .header-link-button {
      @include linkButton(132px, 30px);
      align-items: center;
      font-family: "figtree";
      padding: 5px 16px 5px 12px;
      border-radius: 20px;
      background: var(--Gray-800, #292929);
      @include regularParaghaph(14px, 140%, 400);
      color: #fff;
      white-space: nowrap;
      border: none;
      outline: none;
      img {
        transform: rotate(0deg);
        transition: all 0.5s ease-in-out;
      }
      &:hover {
        background: linear-gradient(
          72deg,
          #7577f7 -40.36%,
          #418ec8 0.47%,
          #595998 27.44%,
          #97558f 43.21%,
          #d05677 59.87%,
          #e47a65 81.63%,
          #f1a060 92.18%
        );
        & > img {
          transform: rotate(90deg);
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }
  @media (max-width: 1039px) {
    .header-sub-container {
      width: 100%;
      // margin: 0px 20px;
      .link-container {
        flex-basis: 60% !important;
        gap: 32px !important;
      }
    }
  }
  @media (max-width: 768px) {
    .header-sub-container {
      width: 100%;
      margin: 0px 0px;
      backdrop-filter: none;
    }
    .link-container {
      display: none !important;
    }
    .mobile-menu {
      display: flex !important;
      cursor: pointer;
      position: relative;
      &Parent {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: -1;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 2px 50px 0px rgba(65, 0, 96, 0.07);
        backdrop-filter: blur(50px);
      }
      .menu-popup {
        position: fixed;
        top: 80px;
        // right: -10px;
        max-width: 768px;
        margin: 0 20px;
        width: 90%;
        left: 0;
        height: 322px;
        padding: 16px;
        z-index: 99;
        border-radius: 16px;
        border: 0.5px solid #fff;
        background: #fff;
        box-shadow: 0px 2px 50px 0px rgba(65, 0, 96, 0.07);
        // backdrop-filter: blur(15px);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        .mobile-links {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .header-links {
            width: 100%;
            height: 36px;
            @include regularParaghaph(16px, 130%, 400);
            font-family: "Figtree";
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }
    }
  }
}
