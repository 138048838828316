@import "../../styles//variables.scss";

.footer-wrapper {
  background: #141414;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  a {
    text-decoration: none;
    width: 100%;
    height: 100%;
  }
  .footer-main-container {
    padding-top: 64px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 64px;
    margin: 0 auto;

    .title-container {
      width: 100%;
      h2 {
        @include regularheading(70px, 120%, 500);
        color: #fafafa;
      }
      h3 {
        @include gradiantHeading(70px, 120%, 500);
      }
      .b-to-top-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
          @include regularParaghaph(15px, 130%, 400);
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          color: #fcfcfc;
          font-family: "Figtree";
          cursor: pointer;

          span {
            display: flex;
            align-items: center;
            position: relative;
            width: 56px;
            height: 56px;
            border-radius: 50px;
            border: 0.5px solid #434343;
            overflow: hidden;
            &:hover {
              background: radial-gradient(
                130.36% 130.36% at 50% 50%,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.29) 100%
              );
            }

            .upArrow {
              position: absolute;
              left: 25%;
            }
            .upArrow-fade-out {
              left: 25%;
              transform: translateY(-150%);
              position: absolute;
              animation-name: fadeOutArrow;
              animation-duration: 1s;
              animation-delay: 0s;
              animation-iteration-count: 1;
              animation-direction: normal;
            }
            .upArrow-gradiant {
              position: absolute;
              left: 25%;
              transform: translateY(150%);
            }
            .upArrow-fade-in {
              transform: translateY(0%);

              left: 25%;
              position: absolute;
              animation-name: fadeInArrow;
              animation-duration: 1s;
              animation-delay: 0s;
              animation-iteration-count: 1;
              animation-direction: normal;
            }
          }
        }
      }
    }
    .footer-card-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 20px;
      .card-wrapper {
        min-width: 200px;
        flex-basis: 24%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        &:hover {
          background: linear-gradient(
            65deg,
            #7577f7 -25.68%,
            #418ec8 -4.99%,
            #595998 11.43%,
            #c35289 26.04%,
            #d9586a 45.13%,
            #9c525c 59.28%,
            #4a4949 76.85%,
            #292929 98.48%
          );
        }
      }
      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        padding: 20px 24px;
        height: 210px;
        margin: 1px;
        border-radius: 8px;
        background: linear-gradient(180deg, #272727 0%, #242424 100%);
        &:hover {
          background: linear-gradient(
            60deg,
            #1f1f1f 45.57%,
            #212121 61.39%,
            #252525 71.88%,
            #333 94.34%
          );
        }

        .tiles {
          width: 64px;
          height: 64px;
          border-radius: 12px;
          background: #141414;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .tiles-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
        .arrow-out {
          display: flex;
          position: relative;
          width: 24px;
          height: 24px;
          cursor: pointer;

          img {
            position: absolute;
            left: 0px;
            top: 0px;
            animation-name: moveArrowOut;
            animation-duration: 0.5s;
            animation-delay: 0s;
            animation-iteration-count: 1;
            animation-direction: normal;
            opacity: 1;
          }
        }

        .arrow-none {
          display: none;
        }
        .contact-details {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;
          h4 {
            @include regularParaghaph(20px, 130%, 400);
            font-family: "Figtree";
            color: #fcfcfc;
          }
          span {
            width: 100%;
            @include regularParaghaph(14px, 130%, 400);
            font-family: "Figtree";
            color: #fcfcfc;
            opacity: 0.6;
            word-break: break-all;
          }
        }
      }
    }
    .termsPrivacy {
      padding: 16px 20px;
      border-radius: 8px;
      background: linear-gradient(180deg, #272727 0%, #242424 100%);
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 16px;
      span {
        color: #fcfcfc;
        font-size: 15px;
        font-weight: 400;
        line-height: 139%;
        opacity: 0.6;
        a {
          color: #fcfcfc;
        }
        &:hover {
          opacity: 1;
        }
        &:nth-child(odd) {
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1039px) {
  .footer-main-container {
    padding: 64px 20px 20px 20px;
    gap: 64px !important;

    .footer-card-container {
      justify-content: center !important;
      gap: 24px !important;
    }
  }

  .copy-rights-container {
    background-image: url("../../assets//images//footer/footerBackgroundTablet.png");
    p {
      font-size: 100px !important;
      transform: translateY(33px);
    }
  }
}

@media (max-width: 1004px) {
  .footer-wrapper {
    height: fit-content;
    .footer-main-container {
      height: fit-content;
      .footer-card-container {
        flex-wrap: wrap;
        .card-wrapper {
          flex-basis: 48%;
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .footer-wrapper {
    height: fit-content;
    .footer-main-container {
      height: fit-content;
      .title-container {
        .b-to-top-container {
          p {
            display: none;
          }
        }
      }

      .footer-card-container {
        flex-direction: column;
        .card-wrapper {
          width: 100%;
          .card {
            margin: 1px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .footer-wrapper {
    .title-container {
      h2 {
        font-size: 32px !important;
      }
      h3 {
        font-size: 32px !important;
      }
    }
    .footer-main-container {
      .footer-card-container {
        .card-wrapper {
          &:nth-child(3) {
            .card {
              .contact-details span {
                width: 73%;
              }
            }
          }
        }
      }
    }
  }
}

.copy-rights-container {
  width: 100%;
  margin-top: 50px;
  background-image: url("../../assets//images//footer/footerBackgroundDesktop.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0% 100%;
  position: relative;
  p {
    width: 100%;
    @include regularParaghaph(130px, 129%, 300);
    color: #fff;
    opacity: 0.05;
    text-align: center;
    // position: absolute;
    transform: translateY(35px);
    left: 0;
    bottom: -35px;
    @media (max-width: 992px) {
      position: static;
      font-size: 106px;
    }
    @media (max-width: 810px) {
      position: static;
      font-size: 50px;
    }
  }
}
.end-line {
  width: 100%;
  height: 8px;
  background: linear-gradient(
    89deg,
    #7577f7 2.28%,
    #418ec8 25.1%,
    #595998 43.36%,
    #c35289 63.64%,
    #d9586a 84.95%,
    #f1a060 99.65%
  );
}
.mobile-b-top {
  display: none;
}
@media (max-width: 768px) {
  .copy-rights-container {
    background-image: url("../../assets//images//footer/footerBackgroundMobile.png");
    p {
      width: 100%;
      font-size: 46px !important;
      transform: translateY(15px);
    }
  }
  .mobile-b-top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    p {
      @include regularParaghaph(14px, 130%, 400);
      color: #fcfcfc;
      font-family: "Figtree";
    }
    span {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      width: 56px;
      height: 56px;
      border-radius: 50px;
      border: 0.5px solid #434343;
      overflow: hidden;
      img {
        position: absolute;
        left: 25%;
      }
    }
  }
}

@keyframes moveArrowOut {
  from {
    left: -15px;
    top: 15px;
    opacity: 0;
  }

  to {
    left: 0px;
    top: 0px;
    opacity: 1;
  }
}
@keyframes fadeOutArrow {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-150%);
  }
}
@keyframes fadeInArrow {
  from {
    transform: translateY(150%);
  }

  to {
    transform: translateY(0%);
  }
}
