@import "../../styles//variables.scss";

.termsData {
  padding: 15vh 0 64px 0;
  &Container {
    height: 100%;
    max-width: 1024px;
  }
  &Title {
    h2 {
      width: fit-content;
      text-align: left;
      font-size: 40px;
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 81.6px */
      background: linear-gradient(
        89deg,
        #7577f7 2.28%,
        #418ec8 25.1%,
        #595998 43.36%,
        #c35289 63.64%,
        #d9586a 84.95%,
        #f1a060 99.65%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &Points {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .dataPoints {
      display: flex;
      flex-direction: column;
      gap: 12px;
      h3 {
        color: var(--Gray-800, #292929);
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
      }
      p {
        color: var(--Gray-700, #424242);
        font-size: 15px;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }

  &LastUpdate {
    color: var(--Gray-800, #292929);
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    span {
      color: #525252;
    }
    padding-top: 20px;
  }
}
