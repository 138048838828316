@import "../../styles/variables.scss";

.bannerParent {
  position: relative;
  margin: 0px;
  outline: none;
  border: none;
}

.banner-main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 90vh;
  gap: 150px;
  padding-top: 35vh;
  background: transparent;
  overflow: hidden;
  // border-top: none;
  // border-bottom: none;

  .title-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .title-swiper-container {
    width: 48%;
    h2 {
      width: 100%;
      text-align: left;
      font-family: "Space Grotesk";
      color: var(--Gray-800, #292929);
      font-size: 70px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 81.6px */
    }
    .scrolling-text-container {
      width: 100%;
      height: 90px;
      overflow: hidden;
    }
    .content-list {
      position: relative;
      width: 100%;
      top: 0;
      left: 0%;
      animation-name: scrollTextLine;
      animation-duration: 8s;
      animation-delay: 2s;
      animation-iteration-count: infinite;
      animation-direction: normal;
    }

    .content-item {
      margin-bottom: 1rem;
    }
    h3 {
      width: fit-content;
      text-align: left;
      font-size: 70px;
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 81.6px */
      background: linear-gradient(
        89deg,
        #7577f7 2.28%,
        #418ec8 25.1%,
        #595998 43.36%,
        #c35289 63.64%,
        #d9586a 84.95%,
        #f1a060 99.65%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all 0.7s ease;
    }
  }
  .welcome-text-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    text-align: left;
    h2 {
      width: 100%;
      text-align: left;
      font-family: "Space Grotesk";
      color: var(--Gray-800, #292929);
      font-size: 70px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 81.6px */
    }
    h3 {
      width: 100%;
      color: #292929;
      font-family: "Space Grotesk";
      font-size: 70px;
      line-height: 120%; /* 31.2px */
      font-weight: 400;
    }
    p {
      width: 100%;
      // color: #525252;
      color: #292929;
      font-family: "Figtree";
      font-size: 24px;
      line-height: 110%; /* 23.4px */

    b{
      font-family: "Space Grotesk";
    }

      span {
        @include gradiantHeading(24px, 140%, 500);
        font-family: "Figtree";
      }
    }
  }
  .space {
    height: 10px;
  }
  .features-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    .icon-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      min-width: none;
      &Layer {
        background: linear-gradient(
          180deg,
          #f6f6f6 31.27%,
          #dfdff3 66.83%,
          #bebeef 100%
        );
        padding: 1px;
        border-radius: 12px;
      }
    }
    .icon {
      @include iconTiles(43px, 43px);
      background-color: #fff;
      border: none;
      position: relative;
      &::after {
        content: "";
        position: absolute;
      }
    }

    .details-section {
      max-width: 130px;
      p {
        color: #292929;
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 18.2px */
      }
    }
  }
  .background-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    // height: 100%;
    z-index: -1;
    height: 100%;
    outline: none;
    border: none;

    video {
      outline: none;
      border: none;
      width: 100%;
      // height: 100%;
      // object-fit: cover;
    }
  }

  @media (max-width: 1280px) {
    .title-section {
      .title-swiper-container {
        width: 60%;
      }
    }
  }
  @media (max-width: 1004px) {
    height: 90vh;
    gap: 40px;
    padding: 150px 20px 20px 20px;

    .background-container {
      video {
        height: 100%;
        object-fit: cover;
      }
    }
    .banner-main-container {
      height: fit-content;
    }
    .title-section {
      flex-direction: column;
      gap: 40px;
      .title-swiper-container {
        width: 100%;
      }
    }
    .welcome-text-container {
      width: 100%;
      p {
        width: 100%;
        line-height: 140%;
      }
    }
    .features-container {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-top: 44px;
      .icon-container {
        flex-basis: 30%;
        gap: 10px;
      }
      .details-section {
        p {
          font-size: 13px;
          line-height: 140%;
        }
      }
    }
  }
  @media (max-width: 768px) {
    height: fit-content;
    gap: 40px;
    padding-top: 19vh;
    .scrolling-text-container {
      height: 50px !important;
      h3 {
        font-size: 40px !important;
      }
    }
    .title-section {
      flex-direction: column;
      gap: 40px;
    }
    .title-swiper-container h2 {
      font-size: 40px !important;
    }
    .welcome-text-container {
      gap: 12px;
      h3 {
        font-size: 26px !important;
        font-family: "Space Grotesk";
      }
      p {
        width: 100% !important;
        font-size: 15px !important;

        span {
          @include gradiantHeading(15px, 140%, 500);
          font-family: "Figtree";
        }
      }
    }
    .features-container {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 44px 0;
      padding-top: 4px;
      .icon {
        min-width: 36px;
        width: 36px;
        height: 36px;
      }
      .icon-container {
        flex-basis: 47%;
      }
    }
  }
}

.safariBgImage {
  object-fit: contain;
  width: 100%;
}
@keyframes scrollTextLine {
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, -25%, 0);
  }

  50% {
    transform: translate3d(0, -51%, 0);
  }

  75% {
    transform: translate3d(0, -79%, 0);
  }
  90% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
