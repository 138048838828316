$primary-text: #292929;
$secondary-text: linear-gradient(
  89deg,
  #7577f7 2.28%,
  #418ec8 25.1%,
  #595998 43.36%,
  #c35289 63.64%,
  #d9586a 84.95%,
  #f1a060 99.65%
);
$ternary-text: #525252;

$secondary-background: linear-gradient(
  89deg,
  #7577f7 2.28%,
  #418ec8 25.1%,
  #595998 43.36%,
  #c35289 63.64%,
  #d9586a 84.95%,
  #f1a060 99.65%
);
$tile-gradient: linear-gradient(
  180deg,
  rgba(190, 190, 239, 0) 50.59%,
  rgba(190, 190, 239, 0.34) 102.78%,
  #bebeef 132.71%
);

@mixin regularheading($font-size, $line-height: 140%, $font-weight: 400) {
  text-align: left;
  font-family: "Space Grotesk";
  color: $primary-text;
  font-size: $font-size;
  font-style: normal;
  font-weight: $font-weight;
  line-height: 120%;
}
@mixin gradiantHeading($font-size, $line-height, $font-weight: 400) {
  text-align: left;
  font-size: $font-size;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: $font-weight;
  line-height: $line-height; /* 81.6px */
  background: $secondary-text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@mixin regularParaghaph($font-size, $line-height: 140%, $font-weight: 400) {
  text-align: left;
  font-family: "Space Grotesk";
  color: $ternary-text;
  font-size: $font-size;
  font-style: normal;
  font-weight: $font-weight;
  line-height: 120%;
}
@mixin iconTiles($width, $height) {
  min-width: $width;
  height: $height;
  width: $width;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #f6f6f6;
  background: $tile-gradient;
  box-shadow: 0px 4px 3px 0px rgba(89, 89, 152, 0.06);
}
@mixin linkButton($width, $height) {
  display: flex;
  width: 132px;
  height: 30px;
  align-items: flex-start;
  gap: 6px;
  cursor: pointer;
  outline: none;
}
