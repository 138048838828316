@import "../../styles/variables.scss";

.data-journey-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: 72px;
  height: fit-content;

  .title-container {
    width: 100%;
    h2 {
      @include regularheading(70px, 120%, 500);
    }
    h3 {
      @include gradiantHeading(70px, 120%, 500);
      width: fit-content;
    }
  }
  .data-module-cards {
    width: 100%;
    padding-top: 72px;
    display: flex;
    flex-direction: column;
    padding-bottom: 72px !important;
    gap: 40px;

    .card {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 24px;
      position: sticky;
      height: 263px;
      border-radius: 24px;
      border: 1px solid #e2e8f0;
      box-shadow: 0px -56px 50px -5px #fff;
      .image-container {
        width: 263px;
        height: 159px;
        align-self: center;
        img {
          width: 100%;
          height: 100%;
          // box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.04);
        }
      }
      .card-title-section {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        text-align: left;
        margin-top: 10px;
        h5 {
          @include regularheading(24px, 130%, 700);
          font-family: "Space Grotesk";
        }
        p {
          max-width: 590px;
          line-height: 140% !important;
          @include regularParaghaph(20px, 140%, 400);
          font-family: "Figtree";
          color: #424242;
        }
      }
      .icon-container {
        @include iconTiles(43px, 43px);
        min-height: 43px;
      }
    }
    .graphical-development {
      top: 70px;
      width: 100%;
      border-radius: 24px;
      border: 1px solid #e2e8f0;
      background: linear-gradient(
        264deg,
        #b4b3fd 1.05%,
        #bfb8fb 5.22%,
        #e8d6fd 13.86%,
        #fafbfb 36.43%,
        #fafbfb 45.95%,
        #f9fafb 58.04%
      );
    }
    .data-cataloging {
      top: 170px;
      width: 100%;
      // margin: 50px auto -190px;
      border-radius: 24px;
      border: 1px solid #e2e8f0;
      background: linear-gradient(
        264deg,
        #efc9e6 1.05%,
        #fad6df 5.22%,
        #fbf3e5 13.86%,
        #fefefa 36.43%,
        #fafbfb 45.95%,
        #f9fafb 58.04%
      );
    }
    .administration {
      top: 270px;
      width: 100%;
      // margin: 150px auto -268px;
      border-radius: 24px;
      border: 1px solid #e2e8f0;
      background: linear-gradient(
        264deg,
        #a3cdfd 1.05%,
        #c7dffb 5.22%,
        #defafa 19.83%,
        #f5fdfd 36.43%,
        #fafbfb 45.95%,
        #f9fafb 58.04%
      );
    }
    .devops {
      top: 370px;
      border-radius: 24px;
      border: 1px solid #e2e8f0;
      background: linear-gradient(
        264deg,
        #f9bffd 1.05%,
        #fac8fa 5.22%,
        #f9ecfb 19.83%,
        #fff 36.43%,
        #fafbfb 45.95%,
        #f9fafb 58.04%
      );
    }
    .hackathon {
      top: 470px;
      border-radius: 24px;
      border: 1px solid #e2e8f0;
      background: linear-gradient(
        264deg,
        #b1e4cc 1.05%,
        #caefdf 5.22%,
        #e3fbf0 19.83%,
        #f3fcf9 36.43%,
        #fafbfb 45.95%,
        #f9fafb 58.04%
      );
    }
    .consulting {
      width: 100%;
      top: 570px;
      border-radius: 24px;
      border: 1px solid #e2e8f0;
      background: linear-gradient(
        264deg,
        #b4b3fd 1.05%,
        #bfb8fb 5.22%,
        #e8d6fd 13.86%,
        #fafbfb 36.43%,
        #fafbfb 45.95%,
        #f9fafb 58.04%
      );
    }
    .talent-exchange {
      width: 100%;
      top: 670px;
      border-radius: 24px;
      border-radius: 24px;
      border: 1px solid #e2e8f0;
      background: linear-gradient(
        264deg,
        #efc9e6 1.05%,
        #fad6df 5.22%,
        #fbf3e5 13.86%,
        #fefefa 36.43%,
        #fafbfb 45.95%,
        #f9fafb 58.04%
      );
    }
    .scroll-first {
      transition: transform 0.3s ease-in-out;
      transform: translateY(-450%);
    }

    .scroll-first.scroll-up {
      transform: translateY(-100%);
    }
  }
}
@media (max-width: 768px) {
  .data-journey-container {
    .title-container {
      h2 {
        font-size: 32px !important;
      }
      h3 {
        font-size: 32px !important;
      }
    }
    .data-module-cards {
      .card {
        flex-direction: column;
        height: fit-content;
        flex-basis: 100% !important;
        .card-title-section {
          width: 100%;
          h5 {
            width: 100%;
            font-size: 15px;
          }
          p {
            width: 100%;
            font-size: 15px;
          }
        }
      }
      .image-container {
        display: none;
      }
    }
  }
}

@media (max-width: 1039px) {
  .data-journey-container {
    padding: 100px 20px 60px 20px;
    .data-module-cards {
      padding-top: 64px;
      gap: 24px;
      flex-direction: initial;
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 0 !important;
      .card {
        position: static;
        flex-direction: column;
        height: 100%;
        width: auto !important;
        flex-basis: 48%;
        min-height: 350px;
        .card-title-section {
          gap: 16px;
          width: 100%;
          h5 {
            font-size: 15px;
          }
        }
      }
      .graphical-development {
        background: linear-gradient(
          319deg,
          #b5b4fe -2.27%,
          #d5c8fb 7.44%,
          #e9dafb 13.52%,
          #f1eafb 23.56%,
          #fafbfc 44.94%,
          #fafbfc 65.06%,
          #fff 98.74%
        );
      }
      .data-cataloging {
        background: linear-gradient(
          316deg,
          #f0cae7 2.41%,
          #f6d3df 9.48%,
          rgba(249, 229, 229, 0.72) 18.82%,
          rgba(251, 245, 234, 0.34) 33.68%,
          #fafbfc 47.34%,
          #fafbfc 66.49%,
          #fff 98.54%
        );
      }
      .administration {
        background: linear-gradient(
          317deg,
          #a4cefd 1.76%,
          rgba(204, 228, 250, 0.79) 8.77%,
          rgba(226, 249, 249, 0.46) 19.67%,
          #fafbfc 42.38%,
          #fafbfc 59.69%,
          #fff 88.66%
        );
      }
      .devops {
        background: linear-gradient(
          317deg,
          #f9c0fd 2.47%,
          rgba(249, 202, 249, 0.41) 11.7%,
          rgba(253, 249, 254, 0.25) 32.09%,
          #fafbfc 47.29%,
          #fafbfc 66.38%,
          #fff 98.34%
        );
      }
      .hackathon {
        background: linear-gradient(
          318deg,
          #b2e5cd 1.48%,
          #cef0e1 11.61%,
          #e0faee 17.94%,
          #fafbfc 40.32%,
          #fafbfc 71.61%,
          #fff 106.68%
        );
      }
      .consulting {
        background: linear-gradient(
          318deg,
          #b5b4fe -2.26%,
          #d5c8fb 8.28%,
          #e9dafb 14.88%,
          #f1eafb 25.76%,
          #fafbfc 48.96%,
          #fafbfc 70.78%,
          #fff 107.31%
        );
      }
      .talent-exchange {
        background: linear-gradient(
          316deg,
          #f0cae7 2.41%,
          #f6d3df 9.48%,
          rgba(249, 229, 229, 0.72) 18.82%,
          rgba(251, 245, 234, 0.34) 33.68%,
          #fafbfc 47.34%,
          #fafbfc 66.49%,
          #fff 98.54%
        );
      }
    }
    .image-container {
      display: none;
    }
  }
}
